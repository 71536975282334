import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-df-status-modal',
  templateUrl: './df-status-modal.component.html',
  styleUrls: ['./df-status-modal.component.css']
})
export class DfStatusModalComponent implements OnInit {

  public flag : any
  public paymentId : any
  public isTransferSlip : any

  jwt: string = ''

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.flag  = this.route.snapshot.paramMap.get("status")
    this.paymentId = this.route.snapshot.paramMap.get("paymentId")
    this.isTransferSlip  = this.route.snapshot.paramMap.get("isTransferSlip")
    
    this.jwt = this.route.snapshot.paramMap.get("jwt")
  }

}
