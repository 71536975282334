import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DfQrModalComponent } from './df-qr-modal/df-qr-modal.component';
import { DfIssueModalComponent } from './df-issue-modal/df-issue-modal.component';
import { DfStatusModalComponent } from './df-status-modal/df-status-modal.component';
import { RoleGuardService } from './manage/guards/role-auth-guard.service';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'user/manage' },
  { path: 'payment/:jwt/:selected', component: DfQrModalComponent },
  { path: 'support', component: DfIssueModalComponent },
  { path: 'support/:txn/:jwt', component: DfIssueModalComponent },
  { path: 'status/:status/:isTransferSlip/:paymentId/:jwt', component: DfStatusModalComponent },
  {
    path: 'manage', loadChildren: () => import('./manage/manage.module').then(m => m.ManageModule),
  },

  {
    path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
