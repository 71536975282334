<br>
<div class="container">
    <div class="row">
        <div class="col">
            <div class="d-flex justify-content-center text-center">
                <h2 *ngIf="flag == 'false'">Transaction Fail</h2>
                <h2 *ngIf="flag == 'true' && isTransferSlip == 'false'">Transaction Successfully</h2>
                <h2 *ngIf="flag == 'true' && isTransferSlip == 'true'">
                    <p>อัพโหลดสลิปเรียบร้อยแล้ว</p> 
                    <p>กรุณารอซักครู่ ระบบกำลังตรวจสอบข้อมูลและทำรายการฝากเงินให้ท่าน</p>
                </h2>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
            <div class="d-flex justify-content-center">
                <img src="../assets/cancel.svg" class="rounded mx-auto d-block img-fluid" *ngIf="flag == 'false'"
                    routerLink='/support/{{this.paymentId}}'>
                <img src="../assets/check.svg" class="rounded mx-auto d-block img-fluid" *ngIf="flag == 'true'">
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
            <div class="d-flex justify-content-center">
                <div *ngIf="flag == 'false'">
                    <div class="row">
                        <div class="col text-center">
                            <h3>
                                กรุณารอ 5-10 นาที หากยอดเงินในบัญชีของคุณถูกตัดแต่ยอดในระบบไม่ถูกปรับ

                            </h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-center">
                            <h3>
                                กดปุ่ม <span style="color: red;"><u>แจ้งปัญหา</u></span>
                            </h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-center">
                            <h3>
                                เพื่อแจ้งปัญหาการเติมเงินของท่าน
                            </h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-center">
                            <button type="button" class="btn btn-success"
                                routerLink='/support/{{this.paymentId}}/{{this.jwt}}'>แจ้งปัญหา</button>
                        </div>
                    </div>
                </div>
                <h3 *ngIf="flag == 'true'">You transaction successfully.</h3>
            </div>
            <br>
            <div class="d-flex justify-content-center"><a href="https://upayqr.com/user/login" target="_blank"><h3>ดูประวัติการฝาก</h3></a></div>
        </div>
    </div>
</div>